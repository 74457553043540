var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{ref:"profile_form",staticClass:"add-form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return (() => {}).apply(null, arguments)}}},[_c('div',{staticClass:"add-form-inner"},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS))?_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ORGANIZATION')} (*)`,"placeholder":_vm.$t('COMMON.ORGANIZATION')}},[_c('organization-selector',{attrs:{"organization":_vm.subscription.organization.id,"filterable":true,"showAll":false},on:{"organizationChanged":(organizationId) => {
              _vm.subscription.organization.id = organizationId;
              _vm.onFormChanged();
            }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}})],1):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_PACKAGES))?_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.PACKAGE')} (*)`,"placeholder":_vm.$t('COMMON.PACKAGE')}},[_c('package-selector',{attrs:{"pack":_vm.subscription.package.id,"filterable":true,"showAll":false},on:{"packageChanged":(packageId) => {
              _vm.subscription.package.id = packageId;
              _vm.onFormChanged();
            }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.package}})],1):_vm._e(),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('SUBSCRIPTIONS.START_TIME')} (*)`}},[_c('flat-picker',{staticClass:"form-control datepicker",attrs:{"config":{
            allowInput: true,
            locale: _vm.$flatPickrLocale(),
          }},on:{"on-change":() => {
              _vm.onFormChanged();
            }},model:{value:(_vm.subscription.start_time),callback:function ($$v) {_vm.$set(_vm.subscription, "start_time", $$v)},expression:"subscription.start_time"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.start_time}})],1),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('SUBSCRIPTIONS.END_TIME')} (*)`}},[_c('flat-picker',{staticClass:"form-control datepicker",attrs:{"config":{
            allowInput: true,
            locale: _vm.$flatPickrLocale(),
          }},on:{"on-change":() => {
              _vm.onFormChanged();
            }},model:{value:(_vm.subscription.end_time),callback:function ($$v) {_vm.$set(_vm.subscription, "end_time", $$v)},expression:"subscription.end_time"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.end_time}})],1)]),_c('div',{staticClass:"submit-wrapper"},[_c('base-button',{staticClass:"btn cancel",attrs:{"type":"button"},on:{"click":_vm.onCloseSubscriptionModal}},[_vm._v(" "+_vm._s(_vm.$t("COMMON.CANCEL"))+" ")]),_c('base-button',{staticClass:"btn btn-sm kw-submit",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.subscription.id ? _vm.$t("COMMON.EDIT") : _vm.$t("COMMON.ADD_ITEM"))+" ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }