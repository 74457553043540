<template>
  <div class="container-fluid">
    <subscription-form
      :loading="loading"
      :subscriptionData="subscription"
      :formErrors="formErrors"
      @subscriptionSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
      @onCloseSubscriptionModal="onCloseSubscriptionModal"
    />
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultSubscription from "../defaultSubscription";
import SubscriptionForm from "../partials/SubscriptionForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    SubscriptionForm,
  },

  mixins: [alertLeave],

  data() {
    return {
      subscription: cloneDeep(defaultSubscription),
      formErrors: null,
      loading: false,
    };
  },

  created() {},

  methods: {
    async handleSubmit(subscription) {
      this.loading = true;

      const subscriptionData = cloneDeep(subscription);
      delete subscriptionData.id;

      try {
        await this.$store.dispatch("subscriptions/add", subscriptionData);
        this.$notify({
          type: "success",
          message: this.$t("SUBSCRIPTIONS.SUBSCRIPTION_ADDED"),
        });
        const subscription = await this.$store.getters[
          "subscriptions/subscription"
        ];
        this.$emit("onViewSubscription", subscription, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },

    onCloseSubscriptionModal() {
      this.$emit("onCloseSubscriptionModal");
    },
  },
};
</script>
